.table {
    padding: 0;
    padding-left: 2.5%;
    padding-right: 3.5%;
    max-height: 523px;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.invert {
    filter: hue-rotate(90deg);
}

.linha_tabela{
    font-size: 12px;
}

.numero_tabela{
    text-align: right;
}

.ocultar{
    display: none;
}