.nav_abas{
  margin-top: 20px;
  min-width: 100%;
  z-index: 20;
}

.space_nav_abas{
  min-width: 100%;
  min-height: 5px;

  /*margin-bottom: 70px;*/
}

.div_help{
  margin-left: auto;
  margin-right: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-width: 35px;
}

.img_help{
  margin-left: auto;
  margin-top: 10px;
  max-width:25px;
  max-height:25px;
}

.linha_sup_menu{
  display: flex;
  width: 100%;
  height: 40px;
}

/* Estilo básico do menu */
.menu {
  position: relative;
  /*display: inline-block;*/
  font-family: Arial, sans-serif;
  text-align: center;
}

/* Estilo básico do menu */
.menu2 {
  position: relative;
  /*display: inline-block;*/
  font-family: Arial, sans-serif;
  margin-left: 70px;

  text-decoration: 'none';
}

/* Estilo do link principal */
.menu-button {
  
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 0px;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 40px;
  text-align: center;
  margin-right: 20px;
  font-size: 18px;
}

/* Estilo do link principal */
.menu-button2 {
  background-color: #000099;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: space-between;
  font-size: 18px;
  height: 40px;
}

  
/* Painel oculto inicialmente */
.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #000099;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  width: 200px;
  /*padding: 10px;*/
  box-sizing: border-box;
  grid-template-columns:  1fr;/*repeat(4, 1fr);*/
  /*gap: 10px;*/
  height: 230px;
  font-size: 14px;
  font-weight: bold;
  
  z-index: 20;
}

.submenu-button{
  background-color: #000099;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 0px;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 40px;
  text-align: center;
  margin-right: 0px;
  font-size: 15px;
}

.submenu-button:hover{
  background-color: rgba(255,255,255,0.5);
  text-decoration-color: #0000FF;
}

/* Painel oculto inicialmente */
.submenu2 {
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #000099;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: 250px;
  padding: 10px;
  box-sizing: border-box;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.menu3 {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  text-align: center;
  margin-left: 290px;
  background-color: #000099;
}