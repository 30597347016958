/*.opaco.navbar.navbar-expand-lg.navbar-light.fixed-top{
    min-width: none;
} */
.navbar_container{
    min-width: 100%;
}


.img-min-saude{ 
    margin-right: 1vh;
    /*width: min(3vw, 40px);*/
    width: min(2.2vw, 32px);
    margin-left: 0.5vh;
    aspect-ratio: 1 / 1.3;
}

.img-fiocruz{ 
    margin-right: 1vh;
    /*width: min(3vw, 40px);*/
    width: min(2.5vw, 36px);
    margin-left: 0.5vh;
    aspect-ratio: 1 / 1.3;
}

.img-coppe{
    /*width: min(6vw, 85px);*/
    width: min(3.2vw, 40px);
    margin-left: 0.25vh;
    margin-right: 0.50vh;
    aspect-ratio: 2 / 1;
}

.img-rockfeller{
    /*width: min(6vw, 85px);*/
    width: min(5vw, 70px);
    margin-left: 0.5vh;
    aspect-ratio: 2 / 0.80;
}

.img-min-gov{
    /*width: min(6vw, 85px);*/
    width: min(6vw, 75px);
    margin-left: 0.5vh;
    aspect-ratio: 2 / 1;
}

.lang-menu{
    margin-top: 0px;
    position: relative;
}

.selected-lang:before{
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
    background-repeat: no-repeat;
}

.lang-menu ul li{
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.lang-menu ul li a{
    min-width: 85px;
    max-height: 30px;
    display: block;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    font-size: 10px;
}

.lang-menu ul li a::before{
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    vertical-align: middle;
    margin-right: 10px;
    /*background-image: url(img/us-flag.gif);*/
    background-size: 23px 18px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.lang-menu ul li a:hover{
    min-width: 85px;
}

.pt:before {
    background-image: url("/public/imagens/br-flag.gif");
}

.en:before {
    background-image: url("/public/imagens/us-flag.gif");
}

.selected-lang.en:before{
    background-image: url("/public/imagens/globe.png");
}

.selected-lang.pt:before{
    background-image: url("/public/imagens/globe.png");
}

a img.aesop_logo{
    min-width: min(9vw, 130px);
    z-index: 0;
}

.right_images{
    margin-left: 7.5%;
    max-width: 300px;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mini_icons{
    margin-right: 0px;
    height: 25px;
    max-width: 75px;
    display: flex;
    justify-content: right;
}

.logos_direita{
    /*margin-left: min(8.5vw, 65%); */
    /*margin-left: min(7.5vw, 19%);*/
    margin-left: 15px;
    min-width: 230px;
}

.switch_color{
    margin-left: 15px;
    /*margin-right: -15px;*/
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
    cursor: pointer;
}

.switch_color.light{
    background-image: url("/public/imagens/light-dark.gif");
    background-repeat: no-repeat;
}

.switch_color.dark{
    background-image: url("/public/imagens/dark-light.gif");
    background-repeat: no-repeat;
}

.inverter {
    filter: invert(1);
}


.lang-menu:hover ul {
    display: block;
}

.navbar-nav{
    margin-left: 5vw; 
    margin-right: 5vw; 
}

.invisible{
    visibility: hidden;
    width: 0;
    height: 0;
}

.sub-titulo{
    position: absolute;
    z-index: -1;
    margin-top: 1vh;/*min(3vw, 7px);*/
    padding-top: 0;
    margin-left: 2%;
    padding-left: 0%;
    min-width: 70%;
    text-align: left;
    font-size: min(1.35vw, 19px);/*19px;*/
    top: 10px;
}

.sub-titulo2{
    position: absolute;
    z-index: -1;
    margin-left: 2.5%;
    padding-left: 0%;
    width: 50%;
    top: 33px;
    text-align: left;
    font-size: 1vw;/*12px;*/
}

.navbar-toggler {
    max-width: 10%;
}

.espacamento {
    min-width: 10px;
}

@media screen and (max-width: 1470px) {
    .right_images{
        max-width: 260px !important;
        min-width: 260px !important;
    }

    .logos_direita{ 
        min-width: 200px;
        margin-left: 10px;
    }

    .img-fiocruz{ 
        margin-right: 1vh;
        /*width: min(3vw, 40px);*/
        width: 30px;
        margin-left: 0.5vh;
        aspect-ratio: 1 / 1.3;
    }

    .img-min-saude{ 
        width: 25px;
        height: 35px;
    }

    .img-min-gov{
        /*width: min(6vw, 85px);*/
        width: 60px;
        margin-left: 0.5vh;
        aspect-ratio: 2 / 1;
    }

    .img-coppe{
        /*width: min(6vw, 85px);*/
        width: 35px;
        margin-left: 0.25vh;
        margin-right: 0.50vh;
        aspect-ratio: 2 / 1;
    }
}

@media screen and (max-width: 995px) {
    .navbar-nav{
        margin-left: 100px; 
        margin-right: 100px; 
    }

    .espacamento {
        max-width: 0px;
    }
    
    .img-min-saude{ 
        width: 30px;
        height: 45px;
    }

    .img-min-gov{
        /*width: min(6vw, 85px);*/
        width: 75px;
        margin-left: 0.5vh;
        aspect-ratio: 2 / 1;
    }

    .img-coppe{
        /*width: min(6vw, 85px);*/
        width: 40px;
        margin-left: 0.25vh;
        margin-right: 0.50vh;
        aspect-ratio: 2 / 1;
    }
    
    .lang-menu{
        min-width: 100px;
        margin-left: 50%;
        margin-right: 20px;
        margin-top: 20px;
        position: relative;
    }

    .switch_color{
        min-width: 50px;
        /*margin-left: calc((100% - 50px)/2);;*/
        margin-top: 20px;
        position: relative;
    }

    .sub-titulo{
        position: absolute;
        z-index: -1;
        margin-top: 0;
        padding-top: 0;
        padding-left: 0px;
        margin-left: 1%;
        min-width: 95%;
        max-width: 95%;
        text-align: center;
        font-size: 3vw;/*10px;*/
        top: 55px;
        color: white;
    }
    
    .sub-titulo2{
        position: absolute;
        z-index: -1;
        margin-left: 15%;
        padding-left: 0px;
        min-width: 80%;
        max-width: 80%;
        top: 85px;
        text-align: left;
        font-size: 2vw;/*8px;*/
    }

    .navbar-toggler {
        position: absolute;
        top: 10px;
        left: 75%;
        background-color: red;
        min-width: 10px;
    }

    .right_images
    {
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        min-height: 125px;
        margin-top: 60px;
    }

    .mini_icons
    {
        margin-right: 0px;
        height: 25px;
        max-width: 600px;
        display: flex;
    }   
    /*
    .mini_icons
    .logos_direita {
        flex: 1;
        background: tomato;
        text-align: center;
        font-size: 1.5em;
    }*/

    .logos_direita{
        margin-left: 0%;
        /*text-align: center;*/
    }
}